import React, { useEffect } from 'react';
import Navigation from './components/Navigation';
import Header from './components/Header';
import DetailedFeatures from './components/DetailedFeatures';
import AppStoreSection from './components/AppStoreSection';
import ComingSoon from './components/ComingSoon';
import Footer from './components/Footer';

function App() {
  useEffect(() => {
    const joinWaitlistButtons = document.querySelectorAll('button');
    
    const handleJoinWaitlist = () => {
      alert('Welcome to HelloLife! We\'ll notify you when our app launches.');
    };

    const handleLearnMore = () => {
      document.querySelector('section')?.scrollIntoView({ behavior: 'smooth' });
    };

    joinWaitlistButtons.forEach(button => {
      if (button.textContent.includes('Join Waitlist')) {
        button.addEventListener('click', handleJoinWaitlist);
      }
      if (button.textContent.includes('Learn More')) {
        button.addEventListener('click', handleLearnMore);
      }
    });

    // Cleanup event listeners
    return () => {
      joinWaitlistButtons.forEach(button => {
        button.removeEventListener('click', handleJoinWaitlist);
        button.removeEventListener('click', handleLearnMore);
      });
    };
  }, []);

  return (
    <div className="min-h-screen flex flex-col bg-gray-100 text-gray-800">
      <Navigation />
      <Header />
      <DetailedFeatures />
      <ComingSoon />
      <AppStoreSection />
      <Footer />
    </div>
  );
}

export default App;
