import React, { useState } from 'react';
import logo from '../assets/images/logo.jpg';
import SignupModal from './SignupModal';

function Navigation() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <nav className="fixed top-0 w-full bg-white shadow-md z-50">
        <div className="container mx-auto px-4 py-3 flex justify-between items-center">
          <div className="text-2xl font-bold text-[#3aafc2] flex items-center">
            <img 
              src={logo} 
              alt="HelloLife Logo" 
              className="h-8 w-8 mr-2 rounded-lg object-cover"
            />
            HelloLife
          </div>
          <button 
            onClick={openModal}
            className="bg-[#3aafc2] text-white px-4 py-2 rounded-full hover:bg-[#72c5c2] transition flex items-center"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 24 24" fill="currentColor">
              <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
              <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
            </svg>
            Get Early Access
          </button>
        </div>
      </nav>
      <SignupModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
      />
    </>
  );
}

export default Navigation;