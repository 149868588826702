import React from 'react';
import { motion } from 'framer-motion';
import { 
  CurrencyDollarIcon, 
  CalendarIcon, 
  GlobeAltIcon 
} from '@heroicons/react/24/outline';

function ComingSoon() {
  const comingSoonFeatures = [
    {
      icon: <CurrencyDollarIcon className="h-12 w-12 mx-auto mb-4 text-[#54b699]" />,
      title: "Budget Creation",
      description: "Smart financial planning integrated with your lifestyle."
    },
    {
      icon: <CalendarIcon className="h-12 w-12 mx-auto mb-4 text-[#54b699]" />,
      title: "Schedule Manager",
      description: "Optimize your time and balance work, wellness, and personal life."
    },
    {
      icon: <GlobeAltIcon className="h-12 w-12 mx-auto mb-4 text-[#54b699]" />,
      title: "Travel Planner",
      description: "Wellness-focused travel recommendations and itineraries."
    }
  ];

  return (
    <section className="bg-white py-16 relative">
      <div className="absolute inset-x-0 top-0 h-1 bg-gradient-to-r from-[#8cc53f] via-[#54b699] to-[#27aae1]"></div>
      <div className="container mx-auto px-4 text-center">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold mb-12 text-gray-800 relative"
        >
          Coming Soon
        </motion.h2>
        <div className="grid md:grid-cols-3 gap-8 mt-8">
          {comingSoonFeatures.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ 
                duration: 0.5, 
                delay: index * 0.2 
              }}
              className="border-2 border-[#3aafc2]/10 rounded-xl p-6 hover:shadow-md transition-all duration-300 ease-in-out"
            >
              <div className="mb-6">
                {feature.icon}
              </div>
              <h3 className="text-2xl font-semibold mb-4 text-gray-800">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default ComingSoon;