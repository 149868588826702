import React from 'react';
import { motion } from 'framer-motion';
import { 
  FireIcon, 
  CalendarIcon, 
  ChartBarIcon, 
  DocumentTextIcon, 
  CubeTransparentIcon, 
  ChatBubbleLeftRightIcon,
  BeakerIcon,
  MoonIcon 
} from '@heroicons/react/24/outline';

// Import all images
import wellnessTrackingScreenshot from '../assets/images/wellness_tracking.jpeg';
import fitnessPlanScreenshot from '../assets/images/fitness_plan.jpeg';
import mealPlanScreenshot from '../assets/images/meal_plan.jpeg';
import recipesScreenshot from '../assets/images/recipes.jpeg';
import pantryScreenshot from '../assets/images/pantry.jpeg';
import socialNetworkingScreenshot from '../assets/images/social_networking.jpeg';

function DetailedFeatures() {
  const features = [
    {
      icon: <ChartBarIcon className="h-8 sm:h-12 w-8 sm:w-12 mx-auto mb-4 text-[#3aafc2]" />,
      title: "Wellness Tracking",
      description: "Monitor your health metrics, set goals, and visualize your wellness journey in real-time.",
      image: wellnessTrackingScreenshot
    },
    {
      icon: <FireIcon className="h-8 sm:h-12 w-8 sm:w-12 mx-auto mb-4 text-[#3aafc2]" />,
      title: "Fitness Plan",
      description: "Personalized workout routines and fitness tracking to help you reach your health goals.",
      image: fitnessPlanScreenshot
    },
    {
      icon: <CalendarIcon className="h-8 sm:h-12 w-8 sm:w-12 mx-auto mb-4 text-[#3aafc2]" />,
      title: "Meal Plan",
      description: "AI-generated nutrition plans tailored to your dietary preferences and health objectives.",
      image: mealPlanScreenshot
    },
    {
      icon: <DocumentTextIcon className="h-8 sm:h-12 w-8 sm:w-12 mx-auto mb-4 text-[#3aafc2]" />,
      title: "Discover Recipes",
      description: "Explore curated recipes, get cooking suggestions, and share your favorite dishes with friends.",
      image: recipesScreenshot
    },
    {
      icon: <CubeTransparentIcon className="h-8 sm:h-12 w-8 sm:w-12 mx-auto mb-4 text-[#3aafc2]" />,
      title: "Smart Pantry",
      description: "Track ingredient expiration, get smart alerts and reorder online, and manage your kitchen inventory.",
      image: null
    },
    {
      icon: <ChatBubbleLeftRightIcon className="h-8 sm:h-12 w-8 sm:w-12 mx-auto mb-4 text-[#3aafc2]" />,
      title: "Social Network",
      description: "Connect with friends, share progress, and motivate each other on your wellness journey.",
      image: socialNetworkingScreenshot
    }
  ];

  return (
    <section className="relative bg-gray-100 py-12 sm:py-16 shadow-[0_-6px_10px_-3px_rgba(0,0,0,0.2)]">
      <div className="container mx-auto px-4 relative z-10">
        <motion.h2 
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-3xl sm:text-4xl font-bold text-center mb-12 sm:mb-16 text-gray-800 flex items-center justify-center"
        >
          Discover HelloLife Features
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-12 px-4 sm:px-0">
          {features.map((feature, index) => (
            <motion.div 
              key={index} 
              initial={{ opacity: 1, y: 0 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ 
                duration: 0 
              }}
              className="bg-white rounded-2xl overflow-hidden transform transition-all duration-300 text-center group mb-4 sm:mb-6 max-w-md mx-auto hover:bg-gray-50 shadow-[0_-2px_4px_-1px_rgba(0,0,0,0.1),0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-2px_rgba(0,0,0,0.1)] hover:shadow-[0_-4px_6px_-2px_rgba(0,0,0,0.1),0_10px_15px_-3px_rgba(0,0,0,0.1),0_4px_6px_-4px_rgba(0,0,0,0.1)] border border-gray-100 opacity-90 hover:opacity-100 scale-95 hover:scale-100"
            >
              <div className="relative overflow-hidden">
                {feature.title === "Smart Pantry" ? (
                  <div className="w-full h-96 sm:h-96 flex items-center justify-center bg-gradient-to-r from-[#3aafc2] to-[#5c6bc0] bg-opacity-50 p-2 pb-0 rounded-t-2xl">
                    <span className="text-white text-2xl font-bold text-center">Screenshot Coming Soon</span>
                  </div>
                ) : (
                  <motion.img 
                    src={feature.image} 
                    alt={feature.title} 
                    className="w-full h-96 sm:h-96 object-cover object-top transition-transform duration-300 group-hover:scale-105 p-2 pb-0 rounded-t-2xl bg-gradient-to-r from-[#3aafc2] to-[#5c6bc0] bg-opacity-50"
                  />
                )}
              </div>
              <div className="p-6 sm:p-8 shadow-[0_-6px_10px_-3px_rgba(0,0,0,0.2)] relative z-10">
                <div className="flex flex-col items-center mb-2 sm:mb-4">
                  <div className="mb-2 sm:mb-4 text-[#3aafc2] w-12 h-12 flex items-center justify-center">
                    {feature.icon}
                  </div>
                  <h3 className="text-xl sm:text-2xl font-bold text-gray-800 mb-2">{feature.title}</h3>
                </div>
                <p className="text-sm sm:text-base text-gray-600">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default DetailedFeatures;