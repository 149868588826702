import React from 'react';

function SignupModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-6 sm:p-8 rounded-2xl w-full max-w-md relative shadow-2xl">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-900 transition-colors duration-300"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 sm:h-6 sm:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div id="mc_embed_signup" className="w-full">
          <form 
            action="https://outlook.us20.list-manage.com/subscribe/post?u=ad57cccb81f73c9ee2d7abbdf&amp;id=4284082148&amp;f_id=00c0c2e1f0" 
            method="post" 
            id="mc-embedded-subscribe-form" 
            name="mc-embedded-subscribe-form" 
            className="validate" 
            target="_blank"
          >
            <div id="mc_embed_signup_scroll">
              <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-center text-[#3aafc2]">Join HelloLife</h2>
              <p className="text-sm sm:text-base text-gray-600 text-center mb-6">Be one of the first to experience this personalized lifestyle assistant!</p>
              
              <div className="indicates-required text-sm text-gray-500 mb-4">
                <span className="asterisk text-red-500">*</span> indicates required
              </div>
              
              <div className="mc-field-group mb-4">
                <label htmlFor="mce-FNAME" className="block mb-2 text-sm text-gray-700">First Name <span className="asterisk text-red-500">*</span></label>
                <input 
                  type="text" 
                  name="FNAME" 
                  className="
                    w-full 
                    px-3 sm:px-4 
                    py-2 sm:py-3 
                    border 
                    border-transparent 
                    bg-gray-100 
                    rounded-xl 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-[#3aafc2] 
                    focus:border-transparent 
                    transition-all 
                    duration-300
                    placeholder-gray-500
                    text-sm sm:text-base
                  "
                  id="mce-FNAME" 
                  required 
                  placeholder="First Name"
                  autoFocus
                />
              </div>
              
              <div className="mc-field-group mb-4">
                <label htmlFor="mce-LNAME" className="block mb-2 text-sm text-gray-700">Last Name <span className="asterisk text-red-500">*</span></label>
                <input 
                  type="text" 
                  name="LNAME" 
                  className="
                    w-full 
                    px-3 sm:px-4 
                    py-2 sm:py-3 
                    border 
                    border-transparent 
                    bg-gray-100 
                    rounded-xl 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-[#3aafc2] 
                    focus:border-transparent 
                    transition-all 
                    duration-300
                    placeholder-gray-500
                    text-sm sm:text-base
                  "
                  id="mce-LNAME" 
                  required 
                  placeholder="Last Name"
                />
              </div>
              
              <div className="mc-field-group mb-4">
                <label htmlFor="mce-EMAIL" className="block mb-2 text-sm text-gray-700">Email Address <span className="asterisk text-red-500">*</span></label>
                <input 
                  type="email" 
                  name="EMAIL" 
                  className="
                    w-full 
                    px-3 sm:px-4 
                    py-2 sm:py-3 
                    border 
                    border-transparent 
                    bg-gray-100 
                    rounded-xl 
                    focus:outline-none 
                    focus:ring-2 
                    focus:ring-[#3aafc2] 
                    focus:border-transparent 
                    transition-all 
                    duration-300
                    placeholder-gray-500
                    text-sm sm:text-base
                  "
                  id="mce-EMAIL" 
                  required 
                  placeholder="Email Address"
                />
              </div>
              
              <div id="mce-responses" className="clear foot">
                <div className="response" id="mce-error-response" style={{display: 'none'}}></div>
                <div className="response" id="mce-success-response" style={{display: 'none'}}></div>
              </div>
              
              <div aria-hidden="true" style={{position: 'absolute', left: '-5000px'}}>
                <input 
                  type="text" 
                  name="b_ad57cccb81f73c9ee2d7abbdf_4284082148" 
                  tabIndex="-1" 
                  value=""
                />
              </div>
              
              <div className="optionalParent">
                <div className="clear foot">
                  <button 
                    type="submit" 
                    name="subscribe" 
                    id="mc-embedded-subscribe" 
                    className="
                      w-full 
                      bg-[#3aafc2] 
                      text-white 
                      py-2 sm:py-3 
                      rounded-xl 
                      hover:bg-opacity-90 
                      transition-all 
                      duration-300 
                      transform 
                      hover:scale-[1.02]
                      disabled:opacity-50
                      disabled:cursor-not-allowed
                      text-sm sm:text-base
                    "
                  >
                    Join Waitlist
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupModal;