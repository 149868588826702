import React from 'react';
import ProductHunt from './ProductHunt';
import AppleStoreBadge from '../assets/images/app_store/app_store_ios_black.svg'
import GoogleStoreBadge from '../assets/images/app_store/google_play.png';

const AppStoreSection = () => {
  return (
    <div className="bg-gradient-to-br from-[#8cc53f] via-[#54b699] to-[#27aae1] py-16 bg-animate animate-gradient">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
            Download HelloLife
          </h2>
          <p className="mt-4 text-xl text-gray-100">
            Available on your favorite platforms soon!
          </p>
          
          <div className="mt-10 flex justify-center space-x-6">
            {/* Apple App Store Button */}
            <a 
              href="#" 
              target="_blank" 
              rel="noopener noreferrer"
              className="transform transition-transform hover:scale-105 hover:brightness-110"
            >
              <img 
                src={AppleStoreBadge} 
                alt="Download on the App Store" 
                className="h-16"
              />
            </a>
            
            {/* Google Play Store Button */}
            <a 
              href="#" 
              target="_blank" 
              rel="noopener noreferrer"
              className="transform transition-transform hover:scale-105 hover:brightness-110"
            >
              <img 
                src={GoogleStoreBadge} 
                alt="Get it on Google Play" 
                className="h-16"
              />
            </a>
          </div>
          
          {/* ProductHunt Badge */}
          <div className="mt-10 flex justify-center">
            <ProductHunt />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppStoreSection;