import React from 'react';

const ProductHunt = () => {
  return (
    <a href="https://www.producthunt.com/posts/hellolife?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-hellolife" target="_blank">
      <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=503166&theme=neutral&t=1738265427197" 
        alt="HelloLife - Your&#0032;AI&#0032;Lifestyle&#0032;Assistant | Product Hunt" 
        style={{width: "250px", height: "54px"}} 
        width="250" 
        height="54" 
      />
    </a>
  );
};

export default ProductHunt;