import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import cookingFeature from '../assets/images/features/cooking.jpg';
import fitnessFeature from '../assets/images/features/fitness.jpg';
import pantryFeature from '../assets/images/features/pantry.jpg';
import budgetFeature from '../assets/images/features/budget.jpg';
import travelFeature from '../assets/images/features/travel.jpg';
import SignupModal from './SignupModal';

function Header() {
  const [scrollY, setScrollY] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <header 
        className="relative overflow-hidden py-16 sm:py-24 text-center bg-gradient-to-br from-[#8cc53f] via-[#54b699] to-[#27aae1] pb-24 sm:pb-36"
        style={{
          backgroundSize: '400% 400%',
          animation: 'gradient-shift 15s ease infinite',
          transform: `translateY(${scrollY * 0.5}px)`
        }}
      >
        <div className="absolute inset-0 bg-noise opacity-10"></div>
        
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="relative z-10 container mx-auto px-4 flex flex-col items-center justify-center h-[60vh] -mb-24"
        >
          {/* Artistic Image Collage */}
          <div className="mb-8 sm:mb-10 w-full max-w-4xl relative max-[932px]:hidden">
            <div className="relative w-full h-56 sm:h-50 flex items-end justify-center mt-16 sm:mt-24">
              {/* Multiple images in an arc shape with position restoration */}
              <motion.div 
                className="absolute w-40 h-48 sm:w-52 sm:h-60 shadow-2xl rounded-xl overflow-hidden z-10 border-4 border-white"
                style={{ 
                  left: '0%', 
                  bottom: '10%'
                }}
                initial={{ 
                  rotate: -15,
                  opacity: 0.9 
                }}
                whileHover={{ 
                  scale: 1.1, 
                  rotate: 0,
                  zIndex: 50, 
                  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' 
                }}
                transition={{ 
                  type: "tween", 
                  duration: 0.2 
                }}
              >
                <img 
                  src={fitnessFeature}
                  alt="Lifestyle 1" 
                  className="w-full h-full object-cover"
                />
              </motion.div>
              <motion.div 
                className="absolute w-56 h-44 sm:w-64 sm:h-52 shadow-2xl rounded-xl overflow-hidden z-20 border-4 border-white"
                style={{ 
                  left: '20%', 
                  bottom: '25%'
                }}
                initial={{ 
                  rotate: 10,
                  opacity: 0.9 
                }}
                whileHover={{ 
                  scale: 1.1, 
                  rotate: 0,
                  zIndex: 50, 
                  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' 
                }}
                transition={{ 
                  type: "tween", 
                  duration: 0.2 
                }}
              >
                <img 
                  src={cookingFeature} 
                  alt="Lifestyle 2" 
                  className="w-full h-full object-cover"
                />
              </motion.div>
              <motion.div 
                className="absolute w-44 h-44 sm:w-52 sm:h-52 shadow-2xl rounded-xl overflow-hidden z-40 border-4 border-white"
                style={{ 
                  left: '45%', 
                  bottom: '15%'
                }}
                initial={{ 
                  rotate: -5,
                  opacity: 0.9 
                }}
                whileHover={{ 
                  scale: 1.1, 
                  rotate: 0,
                  zIndex: 50, 
                  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' 
                }}
                transition={{ 
                  type: "tween", 
                  duration: 0.2 
                }}
              >
                <img 
                  src={pantryFeature}
                  alt="Lifestyle 3" 
                  className="w-full h-full object-cover"
                />
              </motion.div>
              <motion.div 
                className="absolute w-52 h-40 sm:w-60 sm:h-48 shadow-2xl rounded-xl overflow-hidden z-30 border-4 border-white"
                style={{ 
                  right: '20%', 
                  bottom: '35%'
                }}
                initial={{ 
                  rotate: 25,
                  opacity: 0.9 
                }}
                whileHover={{ 
                  scale: 1.1, 
                  rotate: 0,
                  zIndex: 50, 
                  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' 
                }}
                transition={{ 
                  type: "tween", 
                  duration: 0.2 
                }}
              >
                <img 
                  src={travelFeature}
                  alt="Lifestyle 4" 
                  className="w-full h-full object-cover"
                />
              </motion.div>
              <motion.div 
                className="absolute w-48 h-48 sm:w-56 sm:h-56 shadow-2xl rounded-xl overflow-hidden z-20 border-4 border-white"
                style={{ 
                  right: '0%', 
                  bottom: '20%'
                }}
                initial={{ 
                  rotate: -20,
                  opacity: 0.9 
                }}
                whileHover={{ 
                  scale: 1.1, 
                  rotate: 0,
                  zIndex: 50, 
                  boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.25)' 
                }}
                transition={{ 
                  type: "tween", 
                  duration: 0.2 
                }}
              >
                <img 
                  src={budgetFeature}
                  alt="Lifestyle 5" 
                  className="w-full h-full object-cover"
                />
              </motion.div>
            </div>
          </div>
          
          <h1 className="text-3xl sm:text-5xl font-extrabold mb-4 sm:mb-6 text-transparent bg-clip-text bg-white">
            Your AI Lifestyle Companion
          </h1>
          
          <p className="text-base sm:text-xl max-w-2xl mx-auto mb-6 sm:mb-8 text-gray-200 leading-relaxed px-4">
            Build a personalized wellness plan, find and share recipes, use smart pantry management with grocery re-ordering, budget more effectively, connect with friends, optimize your schedule, and live your best life!
          </p>
          
          <motion.button
            onClick={openModal}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-[#023f56] text-white px-6 sm:px-8 py-2 sm:py-3 rounded-full text-base sm:text-lg font-semibold shadow-lg hover:bg-opacity-80 transition-all duration-300 transform"
          >
            Get Early Access
          </motion.button>
        </motion.div>
      </header>
      <SignupModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
      />
    </>
  );
}

export default Header;